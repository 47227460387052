<template>
	<div class="main-content">
		<div class="meunTop">
			<div class="meunLine"></div>
			<div class="meunTitle">页面管理</div>
		</div>
		<div class="flex">
			<div></div>
			<el-button type="primary" @click="addshow=true;addtype=1;resource='1'">添加页面</el-button>
		</div>
		<div class="table-content">
			<el-table :cell-style="{ textAlign: 'center' }" :header-cell-style="{textAlign: 'center',backgroundColor:'#F5F7FA',color:'#333'}" :border="true" :data="data" style="width: 100%">
				<el-table-column prop="id" label="页面ID"></el-table-column>
				<el-table-column prop="pageName" label="页面名称"></el-table-column>
				<el-table-column prop="pageTitle" label="页面标题"></el-table-column>
				<el-table-column prop="pageType" label="页面类型">
					<template style="display: flex;" slot-scope="scope">
						<span>{{scope.row.pageType == 0 ? '游客首页' : '企业员工首页'}}</span>
					</template>
				</el-table-column>
				<el-table-column prop="sort" label="设为首页">
					<template style="display: flex;" slot-scope="scope">
						<el-switch @change="changeSwitch(scope.$index,data)"
							v-model="scope.row.isHoemshow"
							:active-value="1"
							:inactive-value="0"
							active-color="#409EFF"
							inactive-color="#cccccc">
						</el-switch>
					</template>
				</el-table-column>
				<el-table-column prop="createTime" label="创建时间"></el-table-column>
				<el-table-column prop="updateTime" label="修改时间"></el-table-column>
				
				<el-table-column fixed="right" label="操作" width="300">
					<template style="display: flex;" slot-scope="scope">
						<el-button @click.native.prevent="showLink(scope.$index, data,1)" type="text" size="small">
							链接
						</el-button>
						<el-button @click.native.prevent="updataclick(scope.$index, data)" type="text" size="small">
							修改
						</el-button>
						<el-button @click.native.prevent="editIndex(scope.$index, data)" type="text" size="small">
							编辑
						</el-button>
						<el-button @click.native.prevent="deleteList(scope.$index, data)" type="text" size="small">
							删除
						</el-button>
						<el-button @click.native.prevent="copyList(scope.$index, data)" type="text" size="small">
							复制
						</el-button>
						<el-button @click.native.prevent="showLink(scope.$index, data,2)" type="text" size="small">
							查看二维码
						</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>

		<!-- 新增的弹窗 -->
		<el-dialog :visible.sync="addshow" width="700px" :before-close="handleClose" :title="addtype == 1 ? '新增页面' : '修改页面'">
			<el-form class="form_box" :model="sizeForm" label-position='right' ref="sizeForm"  size="small">
				<el-form-item>
                    <template slot-scope="scope">
						<div style="display:flex;" :src="scope.row">
							<p style="min-width:100px;text-align:right;margin-right:10px;"><span style="color:red">*</span>页面名称：</p>
							<el-input :maxlength="30" v-model="sizeForm.pageName" clearable placeholder="请输入页面名称"></el-input>
						</div>
					</template>
				</el-form-item>
				<el-form-item>
                    <template slot-scope="scope">
						<div style="display:flex;" :src="scope.row">
							<p style="min-width:100px;text-align:right;margin-right:10px;"><span style="color:red">*</span>页面标题：</p>
							<el-input :maxlength="50" v-model="sizeForm.pageTitle" clearable placeholder="请输入页面标题"></el-input>
						</div>
					</template>
				</el-form-item>
				<el-form-item>
                    <template slot-scope="scope">
						<div style="display:flex;" :src="scope.row">
							<p style="min-width:100px;text-align:right;margin-right:10px;">页面类型：</p>
							<el-radio-group  v-model="sizeForm.pageType"  style="display:flex;align-items:center;">
                                <el-radio :label="0">游客首页</el-radio>
								<el-radio :label="1">企业会员首页</el-radio>
                            </el-radio-group>
						</div>
					</template>
				</el-form-item>
				<el-form-item>
                    <template slot-scope="scope">
						<div style="display:flex;align-items:center;" :src="scope.row">
							<p style="min-width:100px;text-align:right;margin-right:10px;">设为首页：</p>
							<el-switch 
								v-model="sizeForm.isHoemshow"
								:active-value="1"
								:inactive-value="0"
								active-color="#409EFF"
								inactive-color="#cccccc">
							</el-switch>
						</div>
					</template>
				</el-form-item>
				<el-form-item>
                    <template slot-scope="scope">
						<div style="display:flex;" :src="scope.row">
							<p style="min-width:100px;text-align:right;margin-right:10px;"><span style="color:red">*</span>排序值：</p>
							<el-input type="number" min="0" oninput="if(value.length>11)value=value.slice(0,11)"  @keyup.native="keyVerify($event)" v-model="sizeForm.sort" @blur="sortBlur"></el-input>
						</div>
					</template>
				</el-form-item>
				<el-form-item prop="imgUrl">
                    <template slot-scope="scope">
						<div style="display:flex;" :src="scope.row">
							<p style="min-width:100px;text-align:right;margin-right:10px;"><span style="color:red">*</span>分享图片：</p>
                            <el-upload class="avatar-uploader" action="api/web/tool/file/upload" accept=".jpg,.jpeg,.png" :headers="headers" :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
                                <img v-if="sizeForm.imgUrl" :src="sizeForm.imgUrl" class="avatar">
                                <i v-else class="el-icon-plus avatar-uploader-icon photo"></i>
                            </el-upload>
						</div>
					</template>
				</el-form-item>
				<el-form-item>
                    <template slot-scope="scope">
						<div style="display:flex;" :src="scope.row">
							<p style="min-width:100px;text-align:right;margin-right:10px;"><span style="color:red">*</span>描述：</p>
                    		<el-input type="textarea" :rows="4" :maxlength="500" v-model="sizeForm.pageRemark" placeholder="请输入描述" style="width: 100%"></el-input>
						</div>
					</template>
				</el-form-item>
				<el-form-item size="large" style="display:flex;justify-content:flex-end;">
					<el-button @click.native.prevent='handleClose'>取消</el-button>
					<el-button v-if="addtype==1" type="primary" @click.native.prevent="submitForm('sizeForm')">添加</el-button>
					<el-button v-if="addtype==2" type="primary" @click.native.prevent="onUpdata('sizeForm')">修改</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>

		<!-- 复制链接 -->
		<el-dialog :visible.sync="copyLink" width="600px" :before-close="handleClose" :title="linkType == 1 ? '复制链接' : '预览'">
			<el-form class="form_box" :model="sizeForm" label-position='right' ref="sizeForm" label-width="100px" size="small">
				<p v-if="linkType == 1">pages/index/index</p>

				<div v-else>
					<img :src="erwm" style="display: block;width:50%;margin:0 auto 20px;">
					<p style="font-size:16px;color:#333;text-align:center;">扫描二维码，进行预览</p>
				</div>
				
				<el-form-item size="large" style="display:flex;align-item:center;justify-content:flex-end;">
					<el-button  type="primary" @click.native.prevent='closeLink()'>确定</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>

		<div class="">
			<el-pagination @current-change="handleCurrentChange" :current-page.sync="currentPage"
				:page-count="pageCount" :page-size="pageSize" layout="total, prev, pager, next" :total="total">
			</el-pagination>
		</div>
	</div>
</template>

<script>
	import {
		indexList,indexAdd,indexUpdate,indexDelete,indexSethoemshow,indexCopy,indexPreview
	} from "@/api/platform/index";
	export default {
		name: "bannerSettings",
		data() {
			return {
				resource: "1",
				roleIdsoption: [{label: '显示',value: 1},{label: '隐藏',value: 0}],
				headers: {
					Authorization: this.$store.state.user.Token
				},
				dialogVisible: false,
				data: [],
				addshow: false,
				sizeForm: {
					imgUrl: '',
					isHoemshow: 0,
					pageName: '',
					pageRemark: '',
					pageTitle: '',
					pageType: 0,
					sort: 0,
					id:''
				},
				page: 1,
				pageSize: 10,
				total: 0, //总数据
				currentPage: 0, //当前页页数
				pageCount: 0, //总页数
				addtype: '',
				id: 0,
				copyLink:false,
				linkUrl:'',
				erwm:'',
				linkType:1			//	1为复制链接  2为二维码
			};
		},
		mounted() {
			let that = this;
			that.list(); //banner-列表
		},
		created() {
		},
		methods: {
			//获取列表
			list() {
				indexList({
					page: this.page,
					size: this.pageSize,
				}).then(res => {
					this.data = res.data.records;
					this.total = res.data.total;
					this.currentPage = res.data.current;
					this.pageCount = res.data.pages;
					if (res.data.current > res.data.pages && res.data.pages != 0) {
						this.page = res.data.pages;
						this.list();
					}
				})
			},
			//分页
			handleCurrentChange(val) {
				// console.log(`当前页: ${val}`);
				this.page = val;
				this.list();
			},

			//图片上传
			handleAvatarSuccess(res, file) {
				console.log(res)
				if(res.code == 200){
					this.sizeForm.imgUrl = res.data.path;
				}else{
					this.$message.error(res.msg);
				}
			},
			//图片验证
			beforeAvatarUpload(file) {
				const isJPG = (file.type === 'image/jpeg' || file.type === 'image/png');
				// const isLt2M = file.size / 1024 / 1024 < 2;
				if (!isJPG) {
					this.$message.error('Banner图片只能是 JPG 或 PNG 格式!');
				}
				// if (!isLt2M) {
				// 	this.$message.error('上传头像图片大小不能超过 2MB!');
				// }
				// return isJPG && isLt2M;
				return isJPG
			},
			//关闭提醒
			handleClose(done) {
				this.sizeForm = {
					imgUrl: '',
					isHoemshow: 0,
					pageName: '',
					pageRemark: '',
					pageTitle: '',
					pageType: 0,
					sort: 0,
					id:''
				}
				this.addshow = false;
				this.copyLink = false;
			},

			//修改按钮点击
			updataclick(i,tab){
				console.log(tab[i])
				this.sizeForm = {
					imgUrl: tab[i].imgUrl != null ? tab[i].imgUrl : '',
					isHoemshow: tab[i].isHoemshow,
					pageName: tab[i].pageName != null ? tab[i].pageName : '',
					pageRemark: tab[i].pageRemark != null ? tab[i].pageRemark : '',
					pageTitle: tab[i].pageTitle != null ? tab[i].pageTitle : '',
					pageType: tab[i].pageType != null ? tab[i].pageType : '',
					sort: tab[i].sort,
					id:tab[i].id
				}
				this.addtype = 2
				this.addshow = true
			},

			// 编辑页面
			editIndex(index,data){
				this.$router.push({ name: '/indexDiy', params: { parentId: data[index].id }})
			},

			// 排序值验证
			sortBlur(){
				let that = this;
				if(Number(that.sizeForm.sort) >= 0){
					that.sizeForm.sort = that.sizeForm.sort;
				}else{
					that.$message.error('排序值不能为负数');
				}
			},

			// 复制页面
			copyList(i,tab){
				this.$confirm('确定要复制【'+ tab[i].pageTitle +'】', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					indexCopy(tab[i].id).then(res => {
						if (res.code == 200) {
							this.$message.success('复制成功');
							this.data = [];
							this.page = 1
							this.list();
						} else {
							this.$message.error(res.msg);
						}
					}).catch(e => {
						this.$message.error(e.msg);
					});
				}).catch(_ => {
				});
			},

			// 1查看二维码 2查看链接
			showLink(i,tab,type){
				let that = this;
				if(type == 2){
					that.$axios({
						method:"get", 
						url:"/api/platform/index/preview", 
						headers:{
							Authorization: that.$store.state.user.Token,
						},
						responseType:'arraybuffer',
						params:{homepageId:tab[i].id,page:'pages/preview/preview_index',envVersion:'trial'}
					}).then((res) => { 
						console.log(res);
						const blob = new Blob([res.data])
						//  const imageUrl = URL.createObjectURL(res.data)
						const imageUrl = (window.URL || window.webkitURL).createObjectURL(blob)
						that.erwm = imageUrl;
						
					})
				}else{

				}
				that.linkType = type;
				that.copyLink = true;
			},

			// 关闭链接
			closeLink(){
				this.copyLink = false;
				this.linkUrl = '';
				this.erwm = '';
			},

			// 设为首页
			changeSwitch(i,tab){
				console.log(tab[i]);
				this.$confirm('你确定要将【'+ tab[i].pageName +'】设为首页吗?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let data = {
						id:tab[i].id,
						isHoemshow:tab[i].isHoemshow
					}
					indexSethoemshow(data).then(res => {
						if (res.code == 200) {
							this.$message.success('修改成功');
							this.data = [];
							this.page = 1
							this.list();
						} else {
							this.data[i].isHoemshow = this.data[i].isHoemshow  == 1 ? 2 : 1
							this.$message.error(res.msg);
						}
					})
				}).catch(_ => {
					this.data[i].isHoemshow = this.data[i].isHoemshow  == 1 ? 0 : 1
				});
			},

			//添加提交
			submitForm(formName) {
				if(this.addshow){
					this.$refs[formName].validate((valid) => {
						if (valid) {
							if(this.$refs[formName].model.pageName == ''){
								this.$message.warning('页面名称不能为空');
							}else if(this.$refs[formName].model.pageTitle == ''){
								this.$message.warning('页面标题不能为空');
							}else if( this.$refs[formName].model.imgUrl == ''){
								this.$message.warning('分享图片不能为空');
							}else if(this.$refs[formName].model.pageRemark == ''){
								this.$message.warning('描述不能为空');
							}else if(this.$refs[formName].model.sort.toString() == ''){
								this.$message.warning('排序值不能为空');
							}else if(Number(this.$refs[formName].model.sort) < 0){
								this.$message.warning('排序值不能为负数');
							}else{
								indexAdd({
									imgUrl: this.$refs[formName].model.imgUrl,
									isHoemshow: this.$refs[formName].model.isHoemshow,
									pageName: this.$refs[formName].model.pageName,
									pageRemark: this.$refs[formName].model.pageRemark,
									pageTitle: this.$refs[formName].model.pageTitle,
									pageType: this.$refs[formName].model.pageType,
									sort: this.$refs[formName].model.sort,
								}).then(res=>{
									if(res.code == 200){
										this.$message.success('添加成功');
										this.sizeForm = {
											imgUrl: '',
											isHoemshow: 0,
											pageName: '',
											pageRemark: '',
											pageTitle: '',
											pageType: 0,
											sort: 0,
											id:''
										}
										this.addshow = false
										this.page = 1
										this.list();
									}else{
										this.$message.error(res.msg);
									}
								}).catch(err => {
									this.$message.error(err.msg);
								})
							}
						} else {
							return false;
						}
					});
				}
			},

			//编辑提交
			onUpdata(formName){
				if(this.addshow){
					this.$refs[formName].validate((valid) => {
						if (valid) {
							if(this.$refs[formName].model.pageName == ''){
								this.$message.warning('页面名称不能为空');
							}else if(this.$refs[formName].model.pageTitle == ''){
								this.$message.warning('页面标题不能为空');
							}else if( this.$refs[formName].model.imgUrl == ''){
								this.$message.warning('分享图片不能为空');
							}else if(this.$refs[formName].model.pageRemark == ''){
								this.$message.warning('描述不能为空');
							}else if(this.$refs[formName].model.sort.toString() == ''){
								this.$message.warning('排序值不能为空');
							}else if(Number(this.$refs[formName].model.sort) < 0){
								this.$message.warning('排序值不能为负数');
							}else{
								indexUpdate({
									imgUrl: this.$refs[formName].model.imgUrl,
									isHoemshow: this.$refs[formName].model.isHoemshow,
									pageName: this.$refs[formName].model.pageName,
									pageRemark: this.$refs[formName].model.pageRemark,
									pageTitle: this.$refs[formName].model.pageTitle,
									pageType: this.$refs[formName].model.pageType,
									sort: this.$refs[formName].model.sort,
									id: this.$refs[formName].model.id
								}).then(res=>{
									if (res.code == 200) {
										this.$message.success('修改成功');
										this.sizeForm = {
											imgUrl: '',
											isHoemshow: 0,
											pageName: '',
											pageRemark: '',
											pageTitle: '',
											pageType: 0,
											sort: 0,
											id:''
										}
										this.addshow = false
										this.page = 1
										this.list();
									} else {
										this.$message.error(res.msg);
									}
								}).catch(err => {
									this.$message.error(err.msg);
								})
							}
						} else {
							return false;
						}
					});
				}
			},

			//删除
			deleteList(cur, tab) {
				console.log(tab[cur])
				this.$confirm('你确定要删除【'+ tab[cur].pageTitle +'】吗?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					indexDelete(tab[cur].id).then(res => {
						if (res.code == 200) {
							this.$message.success('删除成功');
							this.page = 1
							this.list();
						} else {
							this.$message.error(res.msg);
						}
					}).catch(e => {
						this.$message.error(e.msg);
					});
				}).catch(_ => {});
			},
		}
	}
</script>

<style scoped>
	.table-content {margin-top: 20px;}
	.active {color: #049EC4;}
	.form_box{padding:0 50px;}
	.avatar{width:150px;}
	.photo{width:150px;height:150px;border:1px solid #eee;display:flex;align-items: center;justify-content:center;font-size:40px;color:#ccc;}
	.flex{display:flex;align-items:center;justify-content:space-between;}

	.meunTop{display:flex;align-items:center;margin-bottom:20px;}
	.meunLine{width:3px;height:20px;background-color:#FFA218;}
	.meunTitle{font-size:20px;margin-left:10px;}
</style>
